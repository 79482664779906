<script>
import tagForm from "@/views/menu/taxonomy/tag/tagForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    tagService: "tagService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  }
})
export default class TagEdit extends mixins(tagForm) {
  formId = "edit-tag-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.tagService.update(this.model.id, this.model);
      this.$router.push(`/taxonomy/${this.model.idTaxonomy}/tag/list`);
      this.toastService.success(this.translations.success.tag_edit);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.tags_edit;
  }

  async afterCreate () {
    this.model = await this.tagService.read(this.$route.params.id);
    this.model.idTaxonomy = this.idTaxonomy;
    this.isReady = true;
  }
}
</script>
